import React from "react";
import { SocialIcon } from "react-social-icons"; // Import SocialIcon
import EN from "../../src/data/en.json";
import AR from "../../src/data/ar.json";
import { BlurFade } from "./ui/BlurFade";

export const Aboutus = ({ lang }) => {
    let Data = EN["AboutUs"]; // Default to English
    lang === "EN" ? (Data = EN["AboutUs"]) : (Data = AR["AboutUs"]); // Switch between English and Arabic

    return (
        <div className="w-full pb-0 px-6 md:px-12 ">
            {/* Header and Description */}
            <div id="aboutus" className="text-center mb-12">
                <BlurFade delay={0.25} inView>
                    <h2 className="text-3xl md:text-4xl font-semibold font-sans text-gray-800">
                        {Data.title}
                        <span className="text-emerald-600"> {Data.greenTitle}</span>
                    </h2>
                </BlurFade>
                <BlurFade delay={0.25 * 2} inView>
                    <p className="text-gray-600 text-base max-w-3xl mx-auto mt-2">{Data.paragraph}</p>
                </BlurFade>
            </div>

            <div className="row price-row flex md:flex-row w-full justify-center items-center ">

                <div className="col-md-6 col-sm-12 w-full flex flex-col gap-6">

                    {/* Content Section */}
                    <BlurFade delay={0.5} inView>
                        <div className="text-gray-700 text-base leading-relaxed ">
                            <p className={`mb-6 section-subtitle text-base ${lang === "EN" ? "" : "text-right"
                                }`}>
                                {Data.description}                        </p>


                            {/* Social Media Icons */}
                            <div className="flex justify-start">
                                <a

                                    href={Data.socialLinks.facebook}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    aria-label="Facebook"
                                >
                                    <SocialIcon className="social-icon" network="facebook"  />
                                </a>

                                <a

                                    href={Data.socialLinks.linkedin}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    aria-label="LinkedIn"
                                >
                                    <SocialIcon network="linkedin" />
                                </a>
                            </div>
                        </div>
                    </BlurFade>

                </div>

                <div className="col-md-6 col-sm-12 w-full flex justify-center items-center">

                    {/* Image Section */}

                    <BlurFade delay={0.75} inView>
                        <div className="md:w-1/2 flex justify-center">
                            <img
                                src="/img/Light/AboutUs 2.png" /* Replace with {Data.imageUrl} if using dynamic URL */
                                alt="FarmTopia Vision"
                                className="rounded-xl shadow-lg object-cover max-w-full"
                            />
                        </div>
                    </BlurFade>

                </div>
            </div>




        </div>
    );
};