"use client";

import React from "react";
import AR from "../../src/data/ar.json";
import EN from "../../src/data/en.json";
import "../styles/Problem.css";
import { BlurFade } from "./ui/BlurFade";

export const ProblemCardsSection = ({ lang }) => {
  // Determine language data
  const Data = lang === "EN" ? EN["Problem"] : AR["Problem"];

  // Map card data
  const data = Data.Cards.map((e) => ({
    title: e.title,
    src: e.img,
    paragraph: e.paragraph,
  }));

  return (
    <section className="problem-cards-section">
      {/* Section Header */}
      <div className="container">
        <BlurFade delay={0.25} inView>
          <h2 className="heading">
            <span className="green">
              {Data.greenTitle}
            </span>{" "}
            <span className="black">
              {Data.title}
            </span>
          </h2>
        </BlurFade>
        <BlurFade delay={0.25 * 2} inView>
          <p className="subtitle">{Data.subTitle}</p>
        </BlurFade>
      </div>

      <div className="cards-container">
        {data.map((card, index) => (
          <div className="card" key={index}>
            {/* Permanent Overlay */}
            <div className="card-overlay-background"></div>

            {/* Card Image */}
            <img src={card.src} alt={card.title} className="card-image" />

            {/* Hover Overlay */}
            <div className="card-overlay text-white p-2">
              <h3 className="text-white">{card.title}</h3>
              <p>{card.paragraph}</p>
            </div>

            {/* Title Outside Hover */}
            <div className="card-title">{card.title}</div>
          </div>
        ))}
      </div>

    </section>
  );
};


