"use client";
import { useEffect } from "react";
import { motion, useAnimation } from "framer-motion";
import { cn } from "../../lib/utils";

export const TextGenerateEffect = ({
  words,
  className,
  filter = true,
  duration = 0.5,
  lang = "EN",
}) => {
  const controls = useAnimation(); // Use useAnimation for controlling animations
  const wordsArray = words.split(" "); // Split words into an array

  useEffect(() => {
    // Restart animation whenever `words` or `lang` changes
    controls.start("visible");
  }, [controls, words, lang]);

  const containerVariants = {
    hidden: {},
    visible: {
      transition: {
        staggerChildren: 0.2, // Stagger effect
      },
    },
  };

  const wordVariants = {
    hidden: { opacity: 0, filter: "blur(10px)" }, // Initial state for words
    visible: {
      opacity: 1,
      filter: filter ? "blur(0px)" : "none", // Apply the filter conditionally
      transition: {
        duration: duration, // Duration for the word animation
      },
    },
  };

  const renderWords = () => {
    return (
      <motion.div
        className={cn(
          "word-container",
          lang === "AR" && "rtl" // Apply RTL class if Arabic
        )}
        variants={containerVariants} // Apply container variants
        initial="hidden"
        animate={controls} // Control animation with the useAnimation hook
      >
        {wordsArray.map((word, idx) => (
          <motion.span
            key={`${word}-${idx}`} // Ensure unique keys
            className="dark:text-black text-white opacity-0"
            variants={wordVariants} // Apply word variants to each span
          >
            {word}{" "}
          </motion.span>
        ))}
      </motion.div>
    );
  };

  return (
    <div className={cn("", className)}>
      <div className="mt-4">
        <div
          className={cn(
            "dark:text-black text-white text-base leading-snug tracking-wide",
            lang === "AR" && "text-right" // Align text to the right for Arabic
          )}
        >
          {renderWords()}
        </div>
      </div>
    </div>
  );
};
