"use client";
import React from "react";
import { FlipWords } from "./ui/FlipWords";
import { TextGenerateEffect } from "./ui/TextGenerateEffect";
import AR from "../../src/data/ar.json";
import EN from "../../src/data/en.json";
import '../styles/Hero.css';


export const HeroSection = ({ lang }) => {
  const Data = lang === "EN" ? EN["Hero"] : AR["Hero"];
  const { wordsFarming, wordsGrowing, paragraph, btn } = Data;

  return (
    <section
      id="hero"
      className="relative w-full h-screen flex items-center justify-center bg-transparent text-white px-6 my-3"
    >
      {/* Video Container */}
      <div className="relative w-full h-full overflow-hidden rounded-2xl">
        <video
          className="absolute inset-0 w-full h-full object-cover"
          poster="/img/Light/hero.png"
          src="https://firebasestorage.googleapis.com/v0/b/farm-topia.appspot.com/o/LandingPage%2FHero%20Section%20caption%20animation.mp4?alt=media&token=29156b8a-fcf0-49e1-88ab-ac4c878b95cb"
          autoPlay
          loop
          muted
          preload="auto"
          playsInline
        />
        <div className="absolute inset-0 bg-black z-0 bg-opacity-20 rounded-2xl"></div>
      </div>

      {/* Content: Bold statement and CTA */}
      <div
        dir={lang === "AR" ? "rtl" : "ltr"}
        className="responsive-container"
      >
        <h1 className="responsive-header">
          {lang === "AR" ? "الزراعة" : "Farming"}{" "}
          <FlipWords className="text-white" words={wordsFarming} lang={lang} />,{" "}
          {lang === "AR" ? "تنمو" : "Growing"}{" "}
          <FlipWords className="text-white" words={wordsGrowing} lang={lang} />
        </h1>

        <div className="responsive-text">
          {/* Pass lang to TextGenerateEffect */}
          <TextGenerateEffect words={paragraph} lang={lang} />
        </div>

        <a
          href="https://beta.platform.farmtopia.farm"
          target="_blank"
          rel="noopener noreferrer"
        >
          <button className="responsive-button">{btn}</button>
        </a>
      </div>



    </section>
  );
};
