import React, { useEffect, useRef, useState } from 'react';
import '../styles/ctaWeb.css';
import AR from "../../src/data/ar.json"
import EN from "../../src/data/en.json"
import { BlurFade } from './ui/BlurFade';
import emailjs from "emailjs-com";

export const CallToActionWeb = ({lang}) => {
  const [language, setLanguage] = useState("EN");
  let Data = EN["CTAWeb"];
  let formDataJSON ={
    name: lang == "EN"? "Name":"الاسم",
    email: lang == "EN"? "Email":"البريد الالكتروني",
    phone: lang == "EN"? "Phone":"رقم الهاتف",
    arcs: lang == "EN"? "Total Farming Area in Acres":"مساحة كم فدان",
    farms: lang == "EN"? "How Many Farms":"كم عدد المزارع",
    Farmed:lang == "EN"? "Number of Farmed Crops":"كم عدد المحاصيل المزروعة",
    company:lang == "EN"? "Company Employee size":"حجم موظفي الشركة",
    btnS: lang == "EN"? "Submit":"أرسال",
    btnC: lang == "EN"? "Close":"الغاء",
    title: lang == "EN"? "Request a Personalized Demo!":"اطلب عرض توضيحي شخصي",
    success: lang == "EN"? "Form submitted successfully!":"تم الارسال بنجاح",
    fail: lang == "EN"? "Failed to send the message. Please try again.":"لم يتم الارسال, حاول مرة أخرى",
  }
  lang == "EN"? Data = EN["CTAWeb"] : Data = AR["CTAWeb"];
  /***** Email From */
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    Arcs:"",
    frames:"",
    Farmed:"",
    company:""
  });

  const [status, setStatus] = useState(""); // For form submission status

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const [isOpen, setIsOpen] = useState(false); // Manage popup visibility

  // Toggle popup visibility
  const togglePopup = () => {
    setIsOpen(!isOpen);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const arcsValue = Number(formData.Arcs);

    if (arcsValue < 100) {
      // Navigate to the Pricing Plan section
      const pricingPlanSection = document.getElementById("pricing-plan"); // Add an ID to your pricing section
      if (pricingPlanSection) {
        togglePopup(); 
        pricingPlanSection.scrollIntoView({ behavior: "smooth" });
      }
      return; // Stop further execution
    }
    emailjs
      .send(
       "service_96zhono","template_ahar09d", 
        formData,
        "ljvsv9MvB5zs8pTXb" 
      )
      .then(
        (response) => {
          console.log("SUCCESS!", response.status, response.text);
          setStatus("SUCCESS");
          setFormData({ name: "", email: "", phone: "", Arcs:"" ,  frames:"",
            Farmed:"",
            company:""}); // Reset the form
          togglePopup(); 
        },
        (error) => {
          console.error("FAILED...", error);
          setStatus("FAILED");
        }
      );
  };
  /***End Email */
  return (
   <div>
     <div className="cta-web">
            <BlurFade delay={0.25} inView>

      <h2 className="section-title "> <span className="text-success">{Data.greenTitle} </span> {Data.title} </h2>
      </BlurFade>
      <BlurFade delay={0.25 * 2} inView>

      <p className="section-subtitle">
      {Data.paragraph}
      </p>
      </BlurFade>

      <div className="content-wrapper">
        {/* Left Column - Text and CTA */}
        <div className="text-column text-left">
        <BlurFade delay={0.25 * 4} inView>

          <h3 className={`ctah3 ${lang === "EN" ? "" : "ar"} `}>{Data.subTitle}</h3>
          </BlurFade>
          <BlurFade delay={0.25 * 6} inView>

          <ul className={`benefits-list ${lang === "EN" ? "" : "ar"} `}>
            <li>{Data.subPara[0]}</li>
            <li>{Data.subPara[1]}</li>
          
          </ul>
          </BlurFade>
          <BlurFade delay={0.25 * 8} inView>
          <div className= {`cta-buttons ${lang === "EN" ? "" : "ar"} `}>
         

            <a href="https://beta.platform.farmtopia.farm/login" className="cta-btn cta-primary">{Data.btn[0]}→</a>
            <button onClick={togglePopup} className="cta-btn cta-secondary">{Data.btn[1]} →</button>
           

          </div>
          </BlurFade>
        </div>

        {/* Right Column - Image */}
        <div className="video-column">
          <video
            src={lang=== "EN"?"https://firebasestorage.googleapis.com/v0/b/farm-topia.appspot.com/o/LandingPage%2FCTA-EN.mp4?alt=media&token=6a186207-0b1f-4fe6-87c1-0a7d06859954":"https://firebasestorage.googleapis.com/v0/b/farm-topia.appspot.com/o/LandingPage%2FCTA-AR.mp4?alt=media&token=979f02ed-bd92-41e3-92af-fcbbf5101b8b"}
            alt="Farm Management System Video"
            className=" farm-image"
            autoPlay
            loop
            muted
            playsInline
          />
        </div>

      </div>

   
    </div>
        {/* Popup Modal */}
        {isOpen && (
        <div className="fixedcta inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50" dir={lang== "EN"? "ltr":"rtl"}>
          <div className="bg-white p-6 rounded-lg shadow-lg w-98">
            {/* Close Button */}
          
            <h2 className="text-xl font-bold mb-4 text-center">{formDataJSON.title}</h2>

            {/* Form */}
            <form onSubmit={handleSubmit} className={` ${lang== "EN"? "":"ar"}`} dir={lang== "EN"? "ltr":"rtl"}>
              <div className="mb-4 col-md-6">
                <label  className={`block text-sm font-medium mb-1 ${lang== "EN"? "":"ar"}`}>{formDataJSON.name}:</label>
                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                  className="w-full px-3 py-2 border border-gray-300 rounded-md"
                />
              </div>
              <div className="mb-4 col-md-6">
                <label className={`block text-sm font-medium mb-1 ${lang== "EN"? "":"ar"}`}>{formDataJSON.phone}:</label>
                <input
                  type="phone"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  required
                  className="w-full px-3 py-2 border border-gray-300 rounded-md"
                />
              </div>
              <div className="mb-4">
                <label className={`block text-sm font-medium mb-1 ${lang== "EN"? "":"ar"}`}>{formDataJSON.email}:</label>
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                  className="w-full px-3 py-2 border border-gray-300 rounded-md"
                />
              </div>
          
              <div className="mb-4 col-md-6">
                <label className={`block text-sm font-medium mb-1 ${lang== "EN"? "":"ar"}`}>{formDataJSON.farms}:</label>
                <input
                  type="Number"
                  name="farms"
                  value={formData.farms}
                  onChange={handleChange}
                  min={0}
                  
                  className="w-full px-3 py-2 border border-gray-300 rounded-md"
                />
              </div>
          
              <div className="mb-4 col-md-6">
                <label className={`block text-sm font-medium mb-1 ${lang== "EN"? "":"ar"}`}>{formDataJSON.arcs}:</label>
                <input
                  type="Number"
                  name="Arcs"
                  value={formData.Arcs}
                  onChange={handleChange}
                  min={0}
                  required
                  className="w-full px-3 py-2 border border-gray-300 rounded-md"
                />
              </div>
              <div className="mb-4">
                <label className={`block text-sm font-medium mb-1 ${lang== "EN"? "":"ar"}`}>{formDataJSON.Farmed}:</label>
                <input
                  type="Number"
                  name="Farmed"
                  value={formData.Farmed}
                  onChange={handleChange}
                  min={0}
                  
                  className="w-full px-3 py-2 border border-gray-300 rounded-md"
                />
              </div>
              <div className="mb-4">
                <label className={`block text-sm font-medium mb-1 ${lang== "EN"? "":"ar"}`}>{formDataJSON.company}:</label>
                <input
                  type="Number"
                  name="company"
                  value={formData.company}
                  onChange={handleChange}
                  min={0}
                  
                  className="w-full px-3 py-2 border border-gray-300 rounded-md"
                />
              </div>
              <button
                type="submit"
                className="bg-green-500 m-2 text-white px-4 py-2 rounded-md"
              >
                {formDataJSON.btnS}
              </button>
              <button
              onClick={togglePopup}
                className="bg-gray-500 m-2 text-white px-4 py-2 rounded-md"
              >
                {formDataJSON.btnC}
              </button>
            </form>

            {/* Submission Status */}
            {status === "SUCCESS" && (
              <p className="text-green-500 mt-2">{formDataJSON.success}</p>
            )}
            {status === "FAILED" && (
              <p className="text-red-500 mt-2">{formDataJSON.fail}</p>
            )}
          </div>
        </div>
      )}
   </div>


  );
}
