import React,{ useState, useEffect }  from 'react';
import '../styles/pricing.css';
import AR from "../../src/data/ar.json"
import EN from "../../src/data/en.json"
import { BlurFade } from './ui/BlurFade';
import emailjs from "emailjs-com";
export const PricingPlansSection = ({lang} ) => {
  const [activeTab, setActiveTab] = useState('6Months');
  const [language, setLanguage] = useState("EN");

  /****** {Vars for this Section} */
  let Data = EN["Plans"];
  lang == "EN"? Data = EN["Plans"] : Data = AR["Plans"];
  const pricingPlans = Data["plan"]
  let btn = "Select Plan"
  lang == "EN"? btn="Select Plan" :btn= "اختر هذة الخطة"
  let EGP = lang === "EN" ? "EGP" : "جنية"; 
  let m6 = lang === "EN" ? "6 Months" : "6 أشهر";
  let y1= lang === "EN" ? "1 Year" : "عام";
  let free = lang === "EN" ? "Signup for free" : "سجل الان مجاني";
  let enterprice= lang === "EN" ? "Request a Quote" : "أطلب حصة"
  const plans = pricingPlans[activeTab];
  let start= lang === "EN" ? "Start With" : "تبدأ من";
  let Acres= lang === "EN" ? "Acres" : "فدان";
let formDataJSON ={
  name: lang == "EN"? "Name":"الاسم",
  email: lang == "EN"? "Email":"البريد الالكتروني",
  phone: lang == "EN"? "Phone":"رقم الهاتف",
  arcs: lang == "EN"? "Total Farming Area in Acres":"مساحة كم فدان",
  farms: lang == "EN"? "How Many Farms":"كم عدد المزارع",
  Farmed:lang == "EN"? "Number of Farmed Crops":"كم عدد المحاصيل المزروعة",
  company:lang == "EN"? "Company Employee size":"حجم موظفي الشركة",
  btnS: lang == "EN"? "Submit":"أرسال",
  btnC: lang == "EN"? "Close":"الغاء",
  title: lang == "EN"? "Request a quote":"اطلب حصتك ",
  success: lang == "EN"? "Form submitted successfully!":"تم الارسال بنجاح",
  fail: lang == "EN"? "Failed to send the message. Please try again.":"لم يتم الارسال, حاول مرة أخرى",
}
 /***** Email From */
 const [formData, setFormData] = useState({
  name: "",
  email: "",
  phone: "",
  Arcs:"",
  frames:"",
  Farmed:"",
  company:""
});

const [status, setStatus] = useState(""); // For form submission status

const handleChange = (e) => {
  setFormData({ ...formData, [e.target.name]: e.target.value });
};
const [isOpen, setIsOpen] = useState(false); // Manage popup visibility

// Toggle popup visibility
const togglePopup = () => {
  setIsOpen(!isOpen);
};
const handleSubmit = (e) => {
  e.preventDefault();
 

  emailjs
    .send(
     "service_96zhono","template_ahar09d", 
      formData,
      "ljvsv9MvB5zs8pTXb" 
    )
    .then(
      (response) => {
        console.log("SUCCESS!", response.status, response.text);
        setStatus("SUCCESS");
        setFormData({ name: "", email: "", phone: "", Arcs:"" ,  frames:"",
          Farmed:"",
          company:""}); // Reset the form
        togglePopup(); 
      },
      (error) => {
        console.error("FAILED...", error);
        setStatus("FAILED");
      }
    );
};
/***End Email */
  const getUserCountry = async () => {
    try {
      const response = await fetch('https://geolocation-db.com/json/');
      const data = await response.json();
      return data.country_code;
    } catch (error) {
      console.error('Error fetching location data:', error);
      return null;
    }
  };
     
  const [currency, setCurrency] = useState("USD");
  const [isEgypt, setIsEgypt] = useState(false);
  
  useEffect(() => {
    const fetchCurrency = async () => {
      const country = await getUserCountry();
      const inEgypt = country === 'EG';
      setIsEgypt(inEgypt);
      setCurrency(inEgypt ? "EGP" : "USD");
    };
  
    fetchCurrency();
  }, []);

  /****** {End Vars} */
  return (
    <section id="pricing-plan" className="d-flex justify-content-center align-items-center min-vh-100 py-5">
   <div>
      {/* Heading */}
      <BlurFade delay={0.25} inView>
      <h2 className="mb-4 text-center">{Data.title}<span className="text-success">{Data.greenTitle} </span></h2>

      
      </BlurFade>
      <BlurFade delay={0.25 * 2} inView>
      <p className="mb-4 text-center section-subtitle">{Data.paragraph}</p>
      </BlurFade>
     
        {/* Tabs for pricing intervals */}
        <div className="d-flex justify-content-center mb-4">
          <ul className="nav nav-tabs">
            {/* <li className="nav-item">
              <button
                className={`nav-link ${activeTab === '3Months' ? 'active' : ''}`}
                onClick={() => setActiveTab('3Months')}
              >
              { m3}
              </button>
            </li> */}
            <li className="nav-item">
              <button
                className={`nav-link ${activeTab === '6Months' ? 'active' : ''}`}
                onClick={() => setActiveTab('6Months')}
              >
                 { m6}
              </button>
            </li>
            <li className="nav-item">
              <button
                className={`nav-link ${activeTab === '1year' ? 'active' : ''}`}
                onClick={() => setActiveTab('1year')}
              >
                {y1} <span className='badge badge-success'>10%</span>
              </button>
            </li>
            {/* <li className="nav-item">
              <button
                className={`nav-link ${activeTab === '2years' ? 'active' : ''}`}
                onClick={() => setActiveTab('2years')}
              >
              {y2}
              </button>
            </li> */}
          </ul>
        </div>

        {/* Pricing Plans */}
        <div className="row price-row justify-content-center">
          {plans.map((plan, index) => (
           
            <div key={index} className="col-md-3 col-sm-12 mb-3">
              <div className="cardP h-100 shadow">
                <div className="card-bodyP">
                  <h5 className={` text-center text-uppercase ${plan.name === "Enterprise" || plan.name=== "المؤسسات الزراعية" ? "text-warning" : "text-success"} `}>{plan.name}</h5>
                  <h2 className="card-price text-center"><small className="text-muted" style={{fontSize:"50%"}}>{plan.name === "Enterprise" || plan.name=== "المؤسسات الزراعية" ? start : ""}</small>{currency== "USD"? "$"+plan.priceG : plan.priceE}<small className="text-muted" style={{fontSize:"50%"}}> {currency== "USD"?"":EGP}/ {lang === "EN" ? "Month" : "شهر"}</small>
                  {plan.name === "Enterprise" || plan.name=== "المؤسسات الزراعية" ? <small className="text-muted" style={{fontSize:"50%"}}>/ {lang === "EN" ? "Acres" : "فدان"}</small> : ""}
                  </h2>
                  <ul className= {`list-unstyled ${lang === "EN" ? "pricing-card" : "pricing-card-ar"} mt-3 mb-4`}>
                    {plan.listBenefits.map((feature, i) => (
                      <li key={i} className="d-flex align-items-center">
                        <svg width="12" height="8" viewBox="0 0 18 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M6.70704 11.8903C6.41354 11.8903 6.12062 11.7801 5.89648 11.5592L0.5 6.23992L2.12112 4.64142L6.70704 9.16174L15.8789 0.121094L17.5 1.71959L7.5176 11.5592C7.29347 11.7801 7.00054 11.8903 6.70704 11.8903Z" fill="#68D585"/>
                        </svg>
                        {feature}
                      </li>
                    ))}
                  </ul>
                  {
                    plan.name === "Enterprise" || plan.name=== "المؤسسات الزراعية" ?  <a  onClick={togglePopup} className={`btn btn-warning btn-block`}>{enterprice} </a>:  <a href="https://beta.platform.farmtopia.farm/login" className={`btn btn-success btn-block`}>{plan.name === "Free" || plan.name === "مجاني"? free: btn} </a>

                  }
                </div>
              </div>
            </div>
          ))}
        </div>
        </div>

              {/* Popup Modal */}
              {isOpen && (
        <div className="fixedcta inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50" dir={lang== "EN"? "ltr":"rtl"}>
          <div className="bg-white p-6 rounded-lg shadow-lg w-98">
            {/* Close Button */}
          
            <h2 className="text-xl font-bold mb-4 text-center">{formDataJSON.title}</h2>

            {/* Form */}
            <form onSubmit={handleSubmit} className={` ${lang== "EN"? "":"ar"}`} dir={lang== "EN"? "ltr":"rtl"}>
              <div className="mb-4 col-md-6">
                <label  className={`block text-sm font-medium mb-1 ${lang== "EN"? "":"ar"}`}>{formDataJSON.name}:</label>
                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                  className="w-full px-3 py-2 border border-gray-300 rounded-md"
                />
              </div>
              <div className="mb-4 col-md-6">
                <label className={`block text-sm font-medium mb-1 ${lang== "EN"? "":"ar"}`}>{formDataJSON.phone}:</label>
                <input
                  type="phone"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  required
                  className="w-full px-3 py-2 border border-gray-300 rounded-md"
                />
              </div>
              <div className="mb-4">
                <label className={`block text-sm font-medium mb-1 ${lang== "EN"? "":"ar"}`}>{formDataJSON.email}:</label>
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                  className="w-full px-3 py-2 border border-gray-300 rounded-md"
                />
              </div>
          
              <div className="mb-4 col-md-6">
                <label className={`block text-sm font-medium mb-1 ${lang== "EN"? "":"ar"}`}>{formDataJSON.farms}:</label>
                <input
                  type="Number"
                  name="farms"
                  value={formData.farms}
                  onChange={handleChange}
                  min={0}
                  
                  className="w-full px-3 py-2 border border-gray-300 rounded-md"
                />
              </div>
          
              <div className="mb-4 col-md-6">
                <label className={`block text-sm font-medium mb-1 ${lang== "EN"? "":"ar"}`}>{formDataJSON.arcs}:</label>
                <input
                  type="Number"
                  name="Arcs"
                  value={formData.Arcs}
                  onChange={handleChange}
                  min={0}
                  required
                  className="w-full px-3 py-2 border border-gray-300 rounded-md"
                />
              </div>
              <div className="mb-4">
                <label className={`block text-sm font-medium mb-1 ${lang== "EN"? "":"ar"}`}>{formDataJSON.Farmed}:</label>
                <input
                  type="Number"
                  name="Farmed"
                  value={formData.Farmed}
                  onChange={handleChange}
                  min={0}
                  
                  className="w-full px-3 py-2 border border-gray-300 rounded-md"
                />
              </div>
              <div className="mb-4">
                <label className={`block text-sm font-medium mb-1 ${lang== "EN"? "":"ar"}`}>{formDataJSON.company}:</label>
                <input
                  type="Number"
                  name="company"
                  value={formData.company}
                  onChange={handleChange}
                  min={0}
                  
                  className="w-full px-3 py-2 border border-gray-300 rounded-md"
                />
              </div>
              <button
                type="submit"
                className="bg-green-500 m-2 text-white px-4 py-2 rounded-md"
              >
                {formDataJSON.btnS}
              </button>
              <button
              onClick={togglePopup}
                className="bg-gray-500 m-2 text-white px-4 py-2 rounded-md"
              >
                {formDataJSON.btnC}
              </button>
            </form>

            {/* Submission Status */}
            {status === "SUCCESS" && (
              <p className="text-green-500 mt-2">{formDataJSON.success}</p>
            )}
            {status === "FAILED" && (
              <p className="text-red-500 mt-2">{formDataJSON.fail}</p>
            )}
          </div>
        </div>
      )}
    </section>
  );
};