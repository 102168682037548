"use client";

import React from 'react';
import SwiperCombined from "./ui/SwiperCombined";
import { BlurFade } from './ui/BlurFade';
import AR from "../../src/data/ar.json"
import EN from "../../src/data/en.json"
import '../styles/Tech.css';

// Create cards after the data array has been defined


export const TechnologySection = ({ lang }) => {
  let Data = EN["Tech"];
  lang == "EN" ? Data = EN["Tech"] : Data = AR["Tech"];

  return (
    <section className="flex flex-col w-full py-4 bg-transparent section-container">

      <div className="container">
        <BlurFade delay={0.25} inView>
          <h2 className="heading">
            <span className="green">
              {Data.greenTitle}
            </span>{" "}
            <span className="black">
              {Data.title}
            </span>
          </h2>
        </BlurFade>
        <BlurFade delay={0.25 * 2} inView>
          <p className="subtitle">{Data.subTitle}</p>
        </BlurFade>
      </div>



      <SwiperCombined lang={lang} />




    </section>
  );
}

