import {React, useState, useEffect} from "react";
import Navbar from '../components/Navbar';
import {Footer} from '../components/footer';
import { BlurFade } from "./ui/BlurFade";
import '../styles/privacy.css';

export const PrivacyPolicy  = ({lang, toggleLanguage}) => {


    return (
        <div>
    	<Navbar lang={lang} toggleLanguage={toggleLanguage} />
       
       {lang=="EN"?(
     <div>
            <header className="bg-success text-white text-center py-4">
         <h1 style={{color:"#fff"}}>Privacy Policy</h1>
         <div className="text-center">Your privacy is important to us.</div>
       </header>
        
          <div className="container my-5">
         
          <div className="mb-4">
            <h2 className="text-success">Table of Contents</h2>
            <ul className="list-unstyled text-left flex">
              <li>
                <a href="#intro" className="text-success text-decoration-none">
                  Introduction
                </a>
              </li>
            
              <li>
                <a href="#use-info" className="text-success text-decoration-none">
                  How We Use the Information & Security Measures
                </a>
              </li>
            
             
    
              <li>
                <a href="#contact" className="text-success text-decoration-none">
                  Contact Us
                </a>
              </li>
            </ul>
          </div>
  
          {/* Sections */}
          <section id="intro" className="mb-4">
            <h3 className="text-success text-left">Introduction</h3>
            <div className="text-left">
              We value your privacy and are committed to protecting your data. This privacy policy explains
              how we collect, use, and secure your information.
            </div>
          
      
      
          <ol class="list-group-numbered text-left">
            <li >1.The app collects and uses personal information for the purpose of providing and improving its services.</li>
            <li >2.Personal information includes your name, email address, and phone number.</li>
            <li >3.The app may also collect information about your device, such as its operating system and version.</li>
            <li >4.The app may use cookies and similar technologies to collect information about your activity within the app.</li>
            <li >5.The app may share your personal information with third-party service providers who help provide and improve the app's services.</li>
            <li >6.The app may also share your personal information in order to comply with legal requirements or to protect its rights and interests.</li>
            <li >7.You may be able to control certain aspects of how your personal information is collected and used through your device settings or by contacting the app's support team.</li>
            <li >8.The app takes reasonable measures to protect your personal information from unauthorized access, disclosure, or destruction.</li>
            <li >9.The app may make updates to this privacy policy from time to time, and you will be notified of any material changes.</li>
          </ol>
        
      
  
          </section>
  
      
  
          <section id="use-info" className="mb-4">
            <h3 className="text-success text-left">How We Use the Information</h3>
            <div className="text-left">
            We take your privacy seriously and are committed to protecting your personal information. This privacy policy explains how we collect, use, and share your information when you use our mobile application. By downloading and using the app, you agree to this privacy policy.
            </div>
            <div className="text-left">
            We may collect information from you when you download, install, and use our mobile application. This information may include:
            </div>
            <div class="container mt-4">
            <div class="row">

  

   
    <div class="col-md-4 mb-3">
      <div class="cardPr">
        <div class="card-bodyP">
          <h5 class="text-left">Personal Information</h5>
          <div class="card-text text-left">
            When you create an account or use the app, we may collect personal information such as your name, email address, phone number, location, and other contact information.
          </div>
        </div>
      </div>
    </div>

 
    <div class="col-md-4 mb-3">
      <div class="cardPr">
        <div class="card-bodyP">
          <h5 class="text-left">Farming Information</h5>
          <div class="card-text text-left">
            This includes all the information you input about your farm and its operations, which we may collect, use, or edit for accuracy and ease of use.
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-4 mb-3">
      <div class="cardPr">
        <div class="card-bodyP">
          <h5 class="text-left">Usage Data</h5>
          <div class="card-text text-left">
            We may collect information about how you use the app, such as the features you access, the time and date of your use, and your interactions with the app.
          </div>
        </div>
      </div>
    </div>
          </div>
 

  <div class="row">
  <div class="col-md-6 mb-3">
      <div class="cardPr">
        <div class="card-bodyP">
          <h5 class="text-left">Device Information </h5>
          <div class="card-text text-left">
          We may collect information about the device you use to access the app, such as your device type, operating system, and unique device identifiers.
          To provide and maintain our mobile application and its features.
 To personalize your experience with the app and to provide you with tailored content and recommendations.
 To improve and optimize the app, its features, and its performance, or offer you related advertisements.
 To communicate with you about the app, its features, and any updates or changes.
 To ensure the accuracy & liability of your farming data inputs.
 To protect the security and integrity of the app and its users.
 To comply with any legal obligations, we may hav
          </div>
        </div>
      </div>
    
</div>
   
<div class="col-md-6 mb-3">
      <div class="cardPr">
        <div class="card-bodyP">
          <h5 class="text-left"> You have certain rights regarding your personal information </h5>
          <div class="card-text text-left">
          including the right to:
<ul>
    <li>• Access and edit your personal information.
</li>
<li>
•Request that we correct any inaccurate or incomplete information we have about you.

</li>
<li>
•Request that we remove your online personal information or deactivate your account.
</li>
</ul>
          </div>
        </div>
      </div>
    </div>



  </div>

  <div class="row">
  <div class="col-md-3 mb-3">
      <div class="cardPr">
        <div class="card-bodyP">
          <h5 class="text-left">Sharing Your Information</h5>
          <div class="card-text text-left">
          We may share your information with third parties in the following circumstances
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-3 mb-3">
      <div class="cardPr">
        <div class="card-bodyP">
          <h5 class="text-left">Service Providers </h5>
          <div class="card-text text-left">
          We may share your information with third-party service providers who perform services on our behalf, such as agronomists, customer support & AI trainers
          </div>
        </div>
      </div>
    </div>
  <div class="col-md-3 mb-3">
      <div class="cardPr">
        <div class="card-bodyP">
          <h5 class="text-left">Legal Requirements </h5>
          <div class="card-text text-left">
          We may disclose your information if it is necessary to comply with any applicable laws, regulations, legal processes, or governmental requests.          </div>
        </div>
      </div>
    </div>
    <div class="col-md-3 mb-3">
      <div class="cardPr">
        <div class="card-bodyP">
          <h5 class="text-left"> Business Transfers </h5>
          <div class="card-text text-left">
          We may disclose or transfer your information in connection with a merger, acquisition, sale of assets, or other business transaction          </div>
        </div>
      </div>
    </div>
  




  </div>



</div>

          </section>
  
     
  
      
  
    
  
       
  
          <section id="contact" className="mb-4">
            <h3 className="text-success text-left">Contact Us</h3>
            <div className="text-left">
 We take appropriate measures to protect the security and integrity of your personal information, including implementing technical and organizational measures to prevent unauthorized access, use, disclosure, alteration, or destruction of your personal information, to the best of our abilities.
 We may update this privacy policy from time to time by posting a new version on our website or within the mobile application. We will notify you of any material changes to this privacy policy by posting a notice within the app or by email.
 If you have any questions or concerns about this privacy policy or our collection and use of your personal information, please contact us at.
              <a href="mailto:info@farmtopia.farm" className="text-success">
              info@farmtopia.farm
              </a>.
            </div>
          </section>
        </div>
     </div>
       ):
       <div>
       <header className="bg-success text-white text-center py-4">
    <h1 style={{color:"#fff"}}> سياسة الخصوصية</h1>
    <div className="text-center">خصوصياتك مهمة جدا بالنسبة لنا</div>
  </header>
   
     <div className="container my-5" dir="rtl">
    
     <div className="mb-4" dir="rtl">
       <h2 className="text-success text-center">المحتوى</h2>
       <ul className="text-right flex" style={{}} dir="rtl">
         <li className="text-right px-6">
           <a href="#intro" className="text-success text-right text-decoration-none">
             مقدمة
           </a>
         </li>
       
         <li className="text-right px-6">
           <a href="#use-info" className="text-success text-right text-decoration-none">
           كيف نستخدم المعلومات وتدابير الأمن
           </a>
         </li>
       
        

         <li className="text-right px-6">
           <a href="#contact" className="text-success text-right text-decoration-none">
        تواصل معنا
           </a>
         </li>
       </ul>
     </div>

     {/* Sections */}
     <section id="intro" className="mb-4" dir="rtl">
       <h3 className="text-success text-right">مقدمة</h3>
       <div className="text-right">
       نحن نقدر خصوصيتك ونلتزم بحماية بياناتك. تشرح سياسة الخصوصية هذه كيفية جمع معلوماتك واستخدامها وتأمينها.
       </div>
       <ol class="text-right">
            <li>يجمع التطبيق ويستخدم المعلومات الشخصية من أجل تقديم وتحسين خدماته.</li>
            <li>تشمل المعلومات الشخصية اسمك، وعنوان بريدك الإلكتروني، ورقم هاتفك.</li>
            <li>قد يجمع التطبيق أيضًا معلومات عن جهازك، مثل نظام التشغيل والإصدار.</li>
            <li>قد يستخدم التطبيق ملفات تعريف الارتباط والتقنيات المماثلة لجمع معلومات عن نشاطك داخل التطبيق.</li>
            <li>قد يشارك التطبيق معلوماتك الشخصية مع مقدمي خدمات الطرف الثالث الذين يساعدون في تقديم وتحسين خدمات التطبيق.</li>
            <li>قد يشارك التطبيق أيضًا معلوماتك الشخصية للامتثال للمتطلبات القانونية أو لحماية حقوقه ومصالحه.</li>
            <li>قد تتمكن من التحكم في بعض جوانب كيفية جمع واستخدام معلوماتك الشخصية من خلال إعدادات جهازك أو عن طريق الاتصال بفريق دعم التطبيق.</li>
            <li>يتخذ التطبيق تدابير معقولة لحماية معلوماتك الشخصية من الوصول غير المصرح به أو الكشف عنها أو تدميرها.</li>
            <li>قد يقوم التطبيق بإجراء تحديثات على سياسة الخصوصية هذه من وقت لآخر، وسيتم إخطارك بأي تغييرات جوهرية.</li>
          </ol>
     </section>

 

     <section id="use-info" className="mb-4" dir="rtl">
       <h3 className="text-success text-right">كيف نستخدم بياناتك</h3>
       <div className="text-right">
       .نحن نأخذ خصوصية بيانتك بجدية وتسعى الشركة لحماية معلومات الشخصية حيث تحدد هذه السياسة كيفية جمع واستخدام وحماية المعلومات الشخصية التي نحصل عليها من المستخدمين لتطبيقنا المحمول. بتحميل واستخدام التطبيق أنت توافق على هذه الشروط والأحكام       </div>
       
       <div className="text-right">
       نقوم بجمع بعض المعلومات الشخصية التي تقدمها لنا عندما تقوم بتنزيل التطبيق أو تستخدمه. وتشمل هذه المعلومات:
       </div>
       <div class="container mt-4">
        
  <div class="row" dir="rtl">
  <div class="col-md-4 mb-3">
      <div class="cardPr">
        <div class="card-bodyP">
          <h5 class=" text-right">المعلومات الشخصية </h5>
          <div class="card-text text-right">
          عند إنشاء حساب أو استخدام التطبيق، قد نجمع معلومات شخصية مثل اسمك وعنوان بريدك الإلكتروني ورقم هاتفك وموقعك ومعلومات الاتصال الأخرى.
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-4 mb-3">
      <div class="cardPr">
        <div class="card-bodyP">
          <h5 class=" text-right"> معلومات الزراعة</h5>
          <div class="card-text text-right">
          يتضمن ذلك جميع المعلومات التي تدخلها حول مزرعتك وعملياتها، والتي قد نجمعها أو نستخدمها أو نحررها من أجل الدقة وسهولة الاستخدام.
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-4 mb-3">
      <div class="cardPr">
        <div class="card-bodyP">
          <h5 class=" text-right">بيانات الاستخدام </h5>
          <div class="card-text text-right">
          قد نجمع معلومات حول كيفية استخدامك للتطبيق، مثل الميزات التي يمكنك الوصول إليها ووقت وتاريخ استخدامك وتفاعلاتك مع التطبيق.
          </div>
        </div>
      </div>
    </div>




  </div>
  
  <div class="row" dir="rtl" >
  <div class="col-md-6 mb-3">
      <div class="cardPr">
        <div class="card-bodyP">
          <h5 class=" text-right">لديك حقوق معينة فيما يتعلق بمعلوماتك الشخصية </h5>
          <div class="card-text text-right">
          بما في ذلك الحق في:
          <br/>
• الوصول إلى معلوماتك الشخصية وتحريرها.
• طلب تصحيح أي معلومات غير دقيقة أو غير كاملة لدينا عنك.
• طلب إزالة معلوماتك الشخصية عبر الإنترنت أو إلغاء تنشيط حسابك.
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-6 mb-3">
      <div class="cardPr">
        <div class="card-bodyP">
          <h5 class=" text-right"> معلومات الجهاز</h5>
          <div class="card-text text-right">
          قد نجمع معلومات حول الجهاز الذي تستخدمه للوصول إلى التطبيق، مثل نوع جهازك ونظام التشغيل ومعرفات الجهاز الفريدة. لتوفير تطبيقنا المحمول وميزاته وصيانته. لتخصيص تجربتك مع التطبيق وتزويدك بمحتوى وتوصيات مخصصة. لتحسين وتحسين التطبيق وميزاته وأدائه، أو تقديم إعلانات ذات صلة لك. للتواصل معك حول التطبيق وميزاته وأي تحديثات أو تغييرات. لضمان دقة ومسؤولية مدخلات بيانات الزراعة الخاصة بك. لحماية أمن وسلامة التطبيق ومستخدميه. للامتثال لأي التزامات قانونية، قد يكون لدينا.
          </div>
        </div>
      </div>
    </div>
 




  </div>
  
  <div class="row" dir="rtl">
  <div class="col-md-3 mb-3">
      <div class="cardPr">
        <div class="card-bodyP">
          <h5 class=" text-right">مشاركة معلوماتك </h5>
          <div class="card-text text-right">
          قد نشارك معلوماتك مع أطراف ثالثة في الظروف التالية
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-3 mb-3">
      <div class="cardPr">
        <div class="card-bodyP">
          <h5 class=" text-right">مقدمو الخدمات </h5>
          <div class="card-text text-right">
          قد نشارك معلوماتك مع مقدمي خدمات تابعين لجهات خارجية يؤدون خدمات نيابة عنا، مثل المهندسين الزراعيين ودعم العملاء ومدربي الذكاء الاصطناعي
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-3 mb-3">
      <div class="cardPr">
        <div class="card-bodyP">
          <h5 class=" text-right">المتطلبات القانونية </h5>
          <div class="card-text text-right">
          قد نفصح عن معلوماتك إذا كان ذلك ضروريًا للامتثال لأي قوانين أو لوائح أو إجراءات قانونية أو طلبات حكومية سارية.
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-3 mb-3">
      <div class="cardPr">
        <div class="card-bodyP">
          <h5 class=" text-right">تحويلات الأعمال </h5>
          <div class="card-text text-right">
          قد نفصح عن معلوماتك أو ننقلها فيما يتعلق بالاندماج أو الاستحواذ أو بيع الأصول أو أي معاملة تجارية أخرى
          </div>
        </div>
      </div>
    </div>



  </div>
   




</div>

     </section>



 



  

     <section id="contact" className="mb-4" dir="rtl">
       <h3 className="text-success text-right">تواصل معنا</h3>
       <div className="text-right">
       نحرص على حماية المعلومات الشخصية التي تحصل عليها من خلال تطبيقنا المحمول بأفضل الطرق الممكنة. ونحن نستخدم التقنيات الأمنية اللازمة لمنع وحماية المعلومات الشخصية من الوصول غير المصرح به.

 قد نقوم بتغيير سياسة الخصوصية من وقت لآخر، وسيتم نشر أي تغييرات على هذه الصفحة. وسيكون لديك الوصول إلى السياسة الجديدة والإصدارات السابقة في أي وقت.

 الاتصال بنا: اذا كان لديك المزيد من الاسئلة أو الاستفسارات بخصوص سياسية الخصوصية وجمع البيانات , الرجاء التواصل معنا على البريد الالكتروني <a href="mailto:info@farmtopia.farm" className="text-success text-right">
         info@farmtopia.farm
         </a>.
       </div>
     </section>
   </div>
</div>
       }
  
     
  
        <Footer lang={lang} />
      </div>

    );
};