import React from 'react';
import '../styles/Footer.css';
import AR from "../../src/data/ar.json"
import EN from "../../src/data/en.json"
export const Footer= ({lang}) => {
  let Data = EN["Footer"];
  lang == "EN"? Data = EN["Footer"] : Data = AR["Footer"];
  return(
    <footer className="custom-footer text-white py-10">
   
    <div className="footer-content">
        <div className="row footer-row">

          {/* Logo Section */}
          <div className="col-md-4 mb-4">
            <img src="/img/Light/logo-white.png" alt="FarmTopia Logo" className="logo" />
          </div>

          {/* Links Section */}
          <div className="col-md-8 text-left footer-row">
          {Data.links.map((navItem, index) => (
      <div className="col-md-4 mb-4" key={index}>
        {/* Main Link (Column Title) */}
        <h5 className={`text-white ${lang=="EN"?"titleh5":"arLink"}`}>{navItem.mainLink}</h5>
        
        {/* SubMenu Links */}
        <ul className="list-unstyled">
          {navItem.subMenu.map((subItem, subIndex) => (
            <li className={lang== "EN"?"enLink":"arLink"} key={subIndex}>
              <a  className="text-white disLinks">
                {subItem.name}
              </a>
            </li>
          ))}
        </ul>
      </div>
    ))}
          </div>

        

        </div>
      
    </div>
    <div className="footer-left">
            
    <p className={`form-text ${lang== "EN"?"":"arLink"}`} style={{color:"#FFF", fontSize:"80%"}} >
            <a href='/privacy' style={{color:"#FFF", fontWeight:"600"}}>{lang==="EN"?"Privacy Policy":"سياسة الخصوصية"}</a>
            {" & "}
            <a href='/terms' style={{color:"#FFF", fontWeight:"600"}}>{lang==="EN"?"Terms of Use For Farmtopia Platform Applications":"شروط الاستخدام لتطبيقات منصة فارمتوبيا"}</a>  
                      </p>
{
  lang=== "EN"?
  <p style={{color:"#FFF", fontSize:"70%"}}>All rights reserved © 2024 Farmtopia</p>:  
   <p className='ar' style={{color:"#FFF", fontSize:"70%"}}>جميع الحقوق محفوظة, فارمتوبيا ©2024</p>
}
</div>
  </footer>
  );
}
