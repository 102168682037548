"use client";

import React, { useState, useEffect } from "react";
import { BlurFade } from "./ui/BlurFade";
import AR from "../../src/data/ar.json";
import EN from "../../src/data/en.json";
import '../styles/Legacy.css';

const icons = [
    "/img/Light/Partly Cloudy Day.png",
    "/img/Light/Farm.png",
    "/img/Light/Healthy Food.png",
    "/img/Light/Artificial Intelligence.png",
];

// Define videos and their durations (in milliseconds)
const videos = [
    { src: "https://firebasestorage.googleapis.com/v0/b/farm-topia.appspot.com/o/LandingPage%2FLegacy%201.mp4?alt=media&token=fb909d88-0aad-4b69-8fa5-1094d6fe411d", duration: 5000 },
    { src: "https://firebasestorage.googleapis.com/v0/b/farm-topia.appspot.com/o/LandingPage%2FLegacy%202.mp4?alt=media&token=689072ab-d82c-4864-aec3-f503386b4670", duration: 10000 },
    { src: "https://firebasestorage.googleapis.com/v0/b/farm-topia.appspot.com/o/LandingPage%2FLegacy%203.mp4?alt=media&token=4ecb77c8-9fa8-46fe-b201-a8b28914b0e0", duration: 7000 },
    { src: "https://firebasestorage.googleapis.com/v0/b/farm-topia.appspot.com/o/LandingPage%2FLegacy%204.mp4?alt=media&token=65ef6c3d-88cb-4eaf-9af4-a56851cb42fc", duration: 5000 },
];

export const Legacy = ({ lang }) => {
    let Data = lang === "EN" ? EN["Legacy"] : AR["Legacy"];
    const [activeIndex, setActiveIndex] = useState(0);
    const [currentVideo, setCurrentVideo] = useState(videos[0]);
    const [isMobile, setIsMobile] = useState(false);

    // Detect viewport size
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768); // Mobile view for screens <= 768px
        };

        handleResize(); // Initial check
        window.addEventListener("resize", handleResize);

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    useEffect(() => {
        setCurrentVideo(videos[activeIndex]);

        const timer = setTimeout(() => {
            setActiveIndex((prevIndex) => (prevIndex + 1) % Data.Cards.length);
        }, videos[activeIndex].duration);

        return () => clearTimeout(timer);
    }, [activeIndex, Data.Cards.length]);

    return (
        <section className="flex flex-col w-full py-4 mb-16 bg-transparent">
            <div className="container">
                <BlurFade delay={0.25} inView>
                    <h2 className="heading">
                        <span className="green">
                            {Data.greenTitle}
                        </span>{" "}
                        <span className="black">
                            {Data.title}
                        </span>
                    </h2>
                </BlurFade>
                <BlurFade delay={0.25 * 2} inView>
                    <p className="subtitle">{Data.subTitle}</p>
                </BlurFade>
            </div>

            {isMobile ? (
                <div className="w-full flex flex-col items-center justify-center px-4 ">
                <div className="flex flex-col items-center justify-center text-center">
                    <div className="flex items-center justify-center w-12 h-12 bg-green-200 rounded-full overflow-hidden mb-0">
                        <img
                            src={icons[activeIndex]}
                            alt={`${Data.Cards[activeIndex].title} Icon`}
                            className="w-6 h-6 object-contain"
                        />
                    </div>
                    <h3 className="text-lg font-semibold text-black">{Data.Cards[activeIndex].title}</h3>
                    <p className="text-sm text-gray-700">{Data.Cards[activeIndex].paragraph}</p>
                </div>
            
                <video
                    key={currentVideo.src}
                    src={currentVideo.src}
                    className="w-full h-auto rounded-lg shadow-lg mt-6"
                    autoPlay
                    loop={false}
                    muted
                    playsInline
                    loading="lazy"
                />
            </div>
            
            ) : (
                <div className="flex row price-row md:flex-row w-full justify-center items-center mt-10 px-2 md:px-20">
                    <div className="w-full col-md-6 col-sm-12 px-2 gap-6 flex flex-col">
                        {Data.Cards.map((header, index) => (
                            <div
                                key={index}
                                className="relative flex items-center space-x-4 cursor-pointer"
                                onClick={() => setActiveIndex(index)}
                            >
                                <div className="h-24 w-[2px] bg-gray-300 rounded-full relative z-20">
                                    {index === activeIndex && (
                                        <div
                                            key={`${activeIndex}-${Date.now()}`}
                                            className="absolute top-0 bg-emerald-600 rounded-full animate-progress"
                                            style={{
                                                animationDuration: `${videos[index].duration}ms`,
                                            }}
                                        />
                                    )}
                                </div>
                                <div className="flex items-center justify-center w-14 h-14 bg-green-200 rounded-full overflow-hidden">
                                    <img
                                        src={icons[index]}
                                        alt={`${header.title} Icon`}
                                        className="w-8 h-8 object-contain"
                                    />
                                </div>
                                <div className={`flex flex-col z-20 ${lang === "AR" ? "text-right" : "text-left"}`}>
                                    <h2 className={`text-lg text-left font-semibold text-black ${lang === "AR" ? "ar" : ""}`}>
                                        {header.title}
                                    </h2>
                                    <p className={`text-sm text-left text-gray-700 ${lang === "AR" ? "ar" : ""} `} >{header.paragraph}</p>
                                </div>
                            </div>
                        ))}
                    </div>

                    <div className="w-full col-md-6 col-sm-12 px-2 gap-6 flex justify-center items-center">
                        <video
                            key={currentVideo.src}
                            src={currentVideo.src}
                            className="w-full h-auto rounded-lg shadow-lg"
                            autoPlay
                            loop={false}
                            muted
                            playsInline
                            loading="lazy"
                        />
                    </div>
                </div>
            )}
        </section>
    );
};
