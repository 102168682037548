import {React, useState, useEffect} from "react";
import Navbar from '../components/Navbar';
import {Footer} from '../components/footer';
import { BlurFade } from "./ui/BlurFade";
import '../styles/privacy.css';

export const TermsNConditions  = () => {
    const [lang, setLang] = useState("EN");
	const [dir, setDir] = useState('ltr');
	const toggleLanguage = () => {
	    const nextLang = lang === "EN" ? "AR" : "EN";
    const nextDir = dir === "ltr" ? "rtl" : "ltr";

    setLang(nextLang); // Update state
    setDir(nextDir); // Update direction state
    localStorage.setItem("preferredLanguage", nextLang); // Persist to localStorage
  };

    return (
        <div>
    	<Navbar lang={lang} toggleLanguage={toggleLanguage} />
       
       {lang=="EN"?(
     <div>
            <header className="bg-success text-white text-center py-4">
         <h1 style={{color:"#fff"}}>terms of use</h1>
    
       </header>
        
          <div className="container my-5">
         <div className="text-success">
         Welcome to our farming web application Farmtopia, owned and operated by Farmtopia for land reclamation shareholding company.
         By downloading, accessing, or using the App, you agree to be bound by these Terms of Use and our Privacy Policy. If you do not agree to these Terms of Use or our Privacy Policy, please do not download, access, or use the App.
            </div>
            <ul class="text-left" style={{listStyle:"square"}}>
            <li>Our mobile application is intended for farmers and agricultural professionals.</li>
            <li>By downloading or using our app, you agree to our terms of use and privacy policy.</li>
            <li>The application is provided "as is" and without warranties of any kind. We make no representations or warranties of any kind, express or implied, as to the operation of the application or the information, content, materials, or products included on the application.</li>
            <li>You are responsible for any content you upload or submit to our app, and it must comply with our content guidelines.</li>
            <li>The user is responsible for ensuring the accuracy and completeness of the data entered into the application, and for using the application in a safe, responsible, and useful manner. The company reserves the right to modify the data for accuracy with prior notice.</li>
            <li>The user agrees to comply with all applicable laws and regulations related to the use of the application and the data generated by it.</li>
            <li>You may use the application only for its intended purposes and in accordance with these terms of use. Any unauthorized use of the application is prohibited.</li>
            <li>The application and its content are protected by copyright and other intellectual property laws. You may not copy or distribute any part of the application or its content without our prior written consent.</li>
            <li>You agree not to reverse engineer, copy, or distribute our app.</li>
            <li>We will not be liable for any damages arising out of your use or inability to use the application, including but not limited to direct, indirect, incidental, punitive, and consequential damages.</li>
            <li>The company is not liable for any damages or losses resulting from the use of the AIoT real-time data or AI tools provided by the application.</li>
            <li>Our app may include links to third-party websites or services, and we are not responsible for any content or actions on those sites.</li>
            <li>The user agrees that the application uses AI and machine learning algorithms to process and analyze data, and that the results are not always 100% accurate. The algorithm relies on sharing and optimizing connected big data analysis for farms while protecting user personal data.</li>
            <li>The user agrees that any data, including satellite imagery, farming data, and AI analysis results, generated by the application or used in context to generate analysis, is owned by the company and can be used by the company for any purpose it deems necessary.</li>
            <li>We may update or change our app and its features at any time.</li>
            <li>We reserve the right to modify these terms of use at any time without prior notice. Your continued use of the application after any changes to these terms of use constitutes your acceptance of the changes.</li>
            <li>We reserve the right to terminate your access to our app at any time for any reason.</li>
            <li>We respect your privacy and will protect your personal information in accordance with our privacy policy to the best of our ability.</li>
            <li>The company will take reasonable measures to protect the security of the data generated by the application, but the user understands that no system is completely secure and that the company cannot guarantee the security of the data beyond its own capabilities.</li>
            <li>The company will collect and use personal data in accordance with its privacy policy and can anonymize and use this data for third-party analytics as deemed necessary. The user should review and accept this before using the application.</li>
            <li>We may use your data for analytics and to improve our app directly or through third-party AI tools.</li>
            <li>These terms of use shall be governed by and construed in accordance with the laws of Egypt.</li>
            <li>If you have any questions or comments about these terms of use, please contact us.</li>
          </ul>
      

        </div>
     </div>
       ):
       <div>
       <header className="bg-success text-white text-center py-4">
    <h1 style={{color:"#fff"}}>شروط الاستخدام</h1>

  </header>
   
  <div className="container my-5" dir="rtl">
         <div class="text-right text-success">
         مرحبًا بكم في تطبيق فارمتوبيا للزراعة الذكية ,بتنزيل التطبيق أو الوصول إليه أو استخدامه ، فإنك توافق على الالتزام بهذه الشروط وسياسة الخصوصية الخاصة بنا. إذا لم توافق على هذه الشروط أو سياسة الخصوصية الخاصة بنا ، فيرجى عدم تنزيل التطبيق أو الوصول إليه أو استخدامه.
            </div>
             <ul class="text-right" style={{listStyle:"square"}}>
            <li>تطبيقنا مخصص للمزارعين والمهنيين في مجال الزراعة.</li>
            <li>من خلال تحميل أو استخدام تطبيقنا، فإنك توافق على شروط الاستخدام وسياسة الخصوصية الخاصة بنا.</li>
            <li>يتم توفير التطبيق "كما هو" وبدون أي ضمانات من أي نوع. نحن لا نقدم أي تمثيلات أو ضمانات من أي نوع، سواء كانت صريحة أو ضمنية، فيما يتعلق بتشغيل التطبيق أو المعلومات أو المحتوى أو المواد أو المنتجات المدرجة في التطبيق.</li>
            <li>أنت مسؤول عن أي محتوى تقوم بتحميله أو تقديمه لتطبيقنا، ويجب أن يتوافق مع إرشادات المحتوى الخاصة بنا.</li>
            <li>المستخدم مسؤول عن ضمان دقة واكتمال البيانات المدخلة في التطبيق، وعن استخدام التطبيق بطريقة آمنة ومسؤولة ومفيدة، تحتفظ الشركة بحق تعديل البيانات لضمان دقتها مع إشعار مسبق.</li>
            <li>يوافق المستخدم على الامتثال لجميع القوانين واللوائح المعمول بها المتعلقة باستخدام التطبيق والبيانات المولدة عنه.</li>
            <li>يمكنك استخدام التطبيق فقط للأغراض المخصصة له ووفقًا لشروط الاستخدام هذه. أي استخدام غير مصرح به للتطبيق محظور.</li>
            <li>التطبيق ومحتواه محميان بحقوق الطبع والنشر وغيرها من قوانين الملكية الفكرية. لا يجوز لك نسخ أو توزيع أي جزء من التطبيق أو محتواه دون الحصول على موافقة كتابية مسبقة منا.</li>
            <li>توافق على عدم عكس هندسة أو نسخ أو توزيع تطبيقنا.</li>
            <li>لن نكون مسؤولين عن أي أضرار تنشأ عن استخدامك أو عدم قدرتك على استخدام التطبيق، بما في ذلك على سبيل المثال لا الحصر الأضرار المباشرة وغير المباشرة والعرضية والعقابية والتبعية.</li>
            <li>الشركة غير مسؤولة عن أي أضرار أو خسائر ناتجة عن استخدام بيانات الوقت الفعلي لـ AIoT أو أدوات الذكاء الاصطناعي المقدمة من التطبيق.</li>
            <li>قد يتضمن تطبيقنا روابط إلى مواقع أو خدمات تابعة لأطراف ثالثة، ونحن غير مسؤولين عن أي محتوى أو إجراءات على تلك المواقع.</li>
            <li>يوافق المستخدم على أن التطبيق يستخدم خوارزميات الذكاء الاصطناعي والتعلم الآلي لمعالجة وتحليل البيانات، وأن النتائج ليست دائمًا دقيقة بنسبة 100%، وتعتمد الخوارزمية على مشاركة وتحسين تحليل البيانات الكبيرة المرتبطة بالمزارع مع حماية البيانات الشخصية للمستخدم.</li>
            <li>يوافق المستخدم على أن أي بيانات، بما في ذلك الصور الفضائية وبيانات الزراعة ونتائج تحليل الذكاء الاصطناعي، التي يتم إنشاؤها بواسطة التطبيق أو التي يتم استخدامها في سياق إنشاء التحليل هي ملك للشركة ويمكن استخدامها من قبل الشركة لأي غرض تراه مناسبًا.</li>
            <li>قد نقوم بتحديث أو تغيير تطبيقنا وميزاته في أي وقت.</li>
            <li>نحتفظ بالحق في تعديل شروط الاستخدام هذه في أي وقت دون إشعار مسبق. إن استمرارك في استخدام التطبيق بعد أي تغييرات في هذه الشروط يعني قبولك لهذه التغييرات.</li>
            <li>نحتفظ بالحق في إنهاء وصولك إلى تطبيقنا في أي وقت ولأي سبب كان.</li>
            <li>نحن نحترم خصوصيتك وسنحمي معلوماتك الشخصية وفقًا لسياسة الخصوصية الخاصة بنا وبأفضل قدراتنا.</li>
            <li>ستتخذ الشركة تدابير معقولة لحماية أمان البيانات المولدة من التطبيق، ولكن المستخدم يفهم أنه لا يوجد نظام آمن تمامًا وأن الشركة لا يمكنها ضمان أمان البيانات بما يتجاوز قدراتها الخاصة.</li>
            <li>ستجمع الشركة وتستخدم البيانات الشخصية وفقًا لسياسة الخصوصية الخاصة بها ويمكنها إخفاء هوية هذه البيانات واستخدامها من أجل تحليلات طرف ثالث حسب الضرورة، يجب على المستخدم مراجعة وقبول ذلك قبل استخدام التطبيق.</li>
            <li>قد نستخدم بياناتك لتحليل البيانات ولتحسين تطبيقنا مباشرة أو من خلال الذكاء الاصطناعي التابع لأطراف ثالثة.</li>
            <li>يجب أن تحكم هذه الشروط وتفسر وفقًا لقوانين جمهورية مصر العربية.</li>
            <li>إذا كان لديك أي أسئلة أو تعليقات حول شروط الاستخدام هذه، يرجى الاتصال بنا.</li>
          </ul>
      

        </div>
</div>
       }
  
     
  
        <Footer lang={lang} />
      </div>

    );
};