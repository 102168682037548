import { useState, useEffect } from 'react';
import Navbar from './components/Navbar';
import { HeroSection } from './components/HeroSection';
import { TechnologySection } from './components/TechnologySection';
import { ProblemCardsSection } from './components/ProblemCardsSection';
import { EcosystemSection } from './components/EcosystemSection';
import { Legacy } from './components/Legacy';
import { PartnersSection } from './components/PartnersSection';
import { TestimonialsSection } from './components/TestimonialsSection';
import { ValueImpactSection } from './components/ValueImpactSection';
import { CallToActionWeb } from './components/CallToActionWeb';
import { CallToActionMobile } from './components/CallToActionMobile';
import { PricingPlansSection } from './components/PricingPlansSection';
import { Aboutus } from './components/Aboutus'; 
import { Footer } from './components/footer';
import {PrivacyPolicy} from './components/pricavyPolicy'
import {TermsNConditions} from "./components/termsConditions"
import JsonData from './data/data.json';
import SmoothScroll from 'smooth-scroll';
import i18n from './i18n';
import {
	BrowserRouter as Router,
	Switch,
	Route,
	Routes,
	Link,
	Navigate,
} from 'react-router-dom';


export const scroll = new SmoothScroll('a[href*="#"]', {
	speed: 1000,
	speedAsDuration: true,
});

const App = () => {


	const [landingPageData, setLandingPageData] = useState({});
	useEffect(() => {
		setLandingPageData(JsonData);
	}, []);
	const [lang, setLang] = useState("EN");
	const [dir, setDir] = useState('ltr');
	const toggleLanguage = () => {
	    const nextLang = lang === "EN" ? "AR" : "EN";
    const nextDir = dir === "ltr" ? "rtl" : "ltr";

    setLang(nextLang); // Update state
    setDir(nextDir); // Update direction state
    localStorage.setItem("preferredLanguage", nextLang); // Persist to localStorage
  };

  // Retrieve and apply language and direction on page load
  useEffect(() => {
    const savedLanguage = localStorage.getItem("preferredLanguage") || "EN";
    const savedDirection = savedLanguage === "AR" ? "rtl" : "ltr";

    setLang(savedLanguage);
    setDir(savedDirection);
    updateLanguage(savedLanguage);
  }, []); // Empty dependency array ensures it runs only once
  
  function updateLanguage(lang) {
	// Update UI and content based on the language
	console.log(`Language changed to ${lang}`);
  }

	const [theme, setTheme] = useState('light');
	useEffect(() => {
		document.documentElement.setAttribute('dir', dir);
		document.documentElement.setAttribute('data-theme', theme);
	}, [dir, theme]);

	return (
		<div className="relative group h-screen" dir={dir}>
      <Routes>
        <Route path="/privacy" element={<PrivacyPolicy lang={lang} toggleLanguage={toggleLanguage} />} />
		<Route path="/terms" element={<TermsNConditions />} />
        <Route path="/" element={<MainLayout lang={lang} dir={dir} toggleLanguage={toggleLanguage} />} />
      </Routes>

   
    </div>

		// <div className='relative group h-screen' dir={dir}>
		// 	{/* Background Image as a Pseudo-Element */}
		// 	<div className="fixed inset-0 bg-[url('../public/img/Light/map.png')] bg-cover bg-center opacity-0 group-hover:opacity-100 transition-opacity duration-500 pointer-events-none z-0 before:absolute before:inset-0 before:opacity-50"></div>


		// 	{/* Main app content */}
		// 	<div className="relative z-20 ">
		// 		<Navbar lang={lang} toggleLanguage={toggleLanguage} />


		// 		<HeroSection lang={lang} />
		// 		<TechnologySection lang={lang} />
		// 		<ProblemCardsSection lang={lang} />
		// 		{/* <EcosystemSection lang={lang} /> */}
		// 		<Legacy lang={lang} />
		// 		<PartnersSection lang={lang} />
		// 		<CallToActionWeb lang={lang} />
		// 		{/* <CallToActionMobile lang={lang} /> */}
		// 		<PricingPlansSection lang={lang} />
		// 		<Aboutus lang={lang}/>
		// 		{/* <TestimonialsSection /> */}
		// 		{/* 		  <ValueImpactSection lang={lang} />*/}
		// 		<Footer lang={lang} />
		// 	</div>
		// </div>
	);
};
const MainLayout = ({ lang, dir, toggleLanguage }) => (
	<div className="relative group h-screen" dir={dir}>
	  {/* Background Image as a Pseudo-Element */}
	  <div className="fixed inset-0 bg-[url('../public/img/Light/map.png')] bg-cover bg-center opacity-0 group-hover:opacity-100 transition-opacity duration-500 pointer-events-none z-0 before:absolute before:inset-0 before:opacity-50"></div>
  
	  {/* Main app content */}
	  <div className="relative z-20">
		<Navbar lang={lang} toggleLanguage={toggleLanguage} />
		<HeroSection lang={lang} />
		<TechnologySection lang={lang} />
		<ProblemCardsSection lang={lang} />
		<Legacy lang={lang} />
		<PartnersSection lang={lang} />
		<CallToActionWeb lang={lang} />
		<PricingPlansSection lang={lang} />
		<Aboutus lang={lang} />
		<Footer lang={lang} />
	  </div>
	</div>
  );
export default App;


