import React, { useState, useEffect, useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation } from 'swiper/modules'; // Import Navigation module
import { Badge } from './Badges';
import '../../styles/Swiper.css';
import AR from "../../data/ar.json"
import EN from "../../data/en.json"

const SwiperCombined = ({ lang }) => {
  const [activeIndex, setActiveIndex] = useState(0); // State to track the active centered slide
  const [progressKey, setProgressKey] = useState(0); // State to trigger progress bar reset
  const prevRef = useRef(null); // Ref for the previous button
  const nextRef = useRef(null); // Ref for the next button
  const swiperRef = useRef(null); // Ref for the Swiper instance
  let Data = EN["Tech"];
  console.log(lang, "Swiper")
  lang == "EN" ? Data = EN["Tech"] : Data = AR["Tech"];

  const headers = Data.Cards.map(e => e.title);

  const images = Data.Cards.map(e => e.img);


  const descriptions = Data.Cards.map(e => e.paragraph);


  const icons = Data.Cards.map((e) => (
    <img src={e.icon} alt={`${e.title} Icon`} className="w-6 h-6 p-[14px]" />
  ));



  useEffect(() => {
    setProgressKey((prevKey) => prevKey + 1); // Reset progress on slide change
  }, [activeIndex]);

  const handleSlideClick = (index) => {
    if (swiperRef.current) {
      swiperRef.current.swiper.slideTo(index); // Center the clicked slide
    }
  };

  return (
    <div className="relative w-full h-screen bg-transparent" dir="ltr">
      {/* Navigation buttons */}

      <button
        ref={prevRef}
        className="absolute left-4 top-[70%] transform -translate-y-1/2 z-50 flex flex-col items-center navigation-button"
      >
        <span className="text-sm text-emerald-500 mb-1">{/* Prev */}</span> {/* Header */}
        <div className="w-10 h-10 flex items-center justify-center border-2 border-emerald-500 rounded-full hover:bg-emerald-500 transition-colors">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="2"
            stroke="currentColor"
            className="w-6 h-6 text-emerald-500 hover:text-white transition-colors"
          >
            <path strokeLinecap="round" strokeLinejoin="round" d="M15 19l-7-7 7-7" />
          </svg>
        </div>
      </button>

      <button 
        ref={nextRef}
        className="absolute right-4 top-[70%] transform -translate-y-1/2 z-50 flex flex-col items-center navigation-button"
      >
        <span className="text-sm text-emerald-500 mb-1">{/* Next */}</span> {/* Header */}
        <div className="w-10 h-10 flex items-center justify-center border-2 border-emerald-500 rounded-full hover:bg-emerald-500 transition-colors">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="2"
            stroke="currentColor"
            className="w-6 h-6 text-emerald-500 hover:text-white transition-colors"
          >
            <path strokeLinecap="round" strokeLinejoin="round" d="M9 5l7 7-7 7" />
          </svg>
        </div>
      </button>


      {/* Main Swiper */}
      <Swiper
        key={lang}
        /* slidesPerView={2}
        spaceBetween={60} */
        breakpoints={{
          340: { slidesPerView: 1, spaceBetween: 10 }, // Small screens
          768: { slidesPerView: 1, spaceBetween: 10 }, // Medium screens
          1024: { slidesPerView: 2, spaceBetween: 16 }, // Large screens
        }}
        centeredSlides={true}
        dir={lang === 'AR' ? 'rtl' : 'ltr'} // Set dir based on language
        autoplay={{
          delay: 8500,
          disableOnInteraction: false,
        }}
        navigation={{
          prevEl: lang === 'AR' ? nextRef.current : prevRef.current,
          nextEl: lang === 'AR' ? prevRef.current : nextRef.current,
        }}
        onBeforeInit={(swiper) => {
          // Dynamically set navigation buttons based on language
          swiper.params.navigation.prevEl = lang === 'AR' ? nextRef.current : prevRef.current;
          swiper.params.navigation.nextEl = lang === 'AR' ? prevRef.current : nextRef.current;
        }} 
        onSlideChange={(swiper) => setActiveIndex(swiper.activeIndex)}
        modules={[Autoplay, Navigation]}
        className="mySwiper z-50"
        ref={swiperRef}
      >
        {descriptions.map((description, index) => (
          <SwiperSlide key={index} onClick={() => handleSlideClick(index)} dir={lang === 'AR' ? 'rtl' : 'ltr'} // Set dir based on language
          >
            <div className="flex flex-col items-center h-full">
              {/* Content Wrapper with Equal Height */}
              <div className="flex flex-col items-center justify-between min-h-[230px] w-full"> {/* Adjust min height */}
                {/* Icon with circular background */}
                <div className="flex items-center justify-center w-14 h-14 bg-green-200 rounded-full mb-2">
                  {icons[index]} {/* Render corresponding icon */}
                </div>

                {/* Dynamic Headers */}
                <div className="flex gap-3 items-center">
                  <h3 className="text-xl mb-2">{headers[index]}</h3>
                  {
                    (headers[index] === 'Precision Irrigation Solutions with Predictive AI Models' ||
                      headers[index] === 'Advanced Fertilizer Management' ||
                      headers[index] === 'Financial & Yield Management' ||
                      headers[index] === 'AI-Powered Crop Analytics by Hathor Copilot' ||
                      headers[index] === 'حلول الري الدقيق مع نماذج الذكاء الاصطناعي التنبؤية' ||
                      headers[index] === 'إدارة متقدمة للتسميد' ||
                      headers[index] === 'إدارة مالية وتشغيليه' ||
                      headers[index] === 'تحليلات المحاصيل المدعومة بالذكاء الاصطناعي بواسطة Hathor Copilot') && (
                      <Badge>
                        {lang === 'EN' ? 'Soon' : 'قريبًا'}
                      </Badge>
                    )
                  }
                </div>

                {/* Description */}
                <p className="text-center mb-3 max-w-lg">{description}</p>

                {/* Progress Bar */}
                <div className="w-full h-0.5 bg-gray-300 relative">
                  {activeIndex === index && (
                    <div
                      className={`absolute top-0 ${lang === "AR" ? "right-0" : "left-0"} h-full bg-emerald-500 rounded-full`}
                      style={{
                        animation: lang === "AR" ? `progress-animation-reverse ${8500}ms linear` : `progress-animation ${8500}ms linear`,
                        animationDuration: "8.4s",
                      }}
                    ></div>
                  )}
                </div>

              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>



      {/* Fullscreen image */}
      {images[activeIndex] && (
        <div className="fullscreen-image-container z-10">
          <img
            src={images[activeIndex]}
            alt={`tech-img-${activeIndex}`}
            className="fullscreen-image"
          />
        </div>
      )}


    </div>
  );
};

export default SwiperCombined;
