

"use client";
import React from "react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import AR from "../../../src/data/ar.json"
import EN from "../../../src/data/en.json"
import { useState } from "react";


const transition = {
  type: "spring",
  mass: 0.5,
  damping: 11.5,
  stiffness: 100,
  restDelta: 0.001,
  restSpeed: 0.001,
};



export const MenuItem = ({
  setActive,
  active,
  item,
  children,
  
}) => {

  const isActive = active === item;

  

  return (
    <div
      onMouseEnter={() => setActive(item)}
      className="relative"
    >
      <motion.p
        transition={{ duration: 0.3 }}
        className="cursor-pointer text-black text-base hover:opacity-[0.9] dark:text-white flex items-center"
      >
        {item}
        <motion.img
          src="/img/Light/arrow.png" // Path to your arrow icon
          alt="Arrow Icon"
          initial={{ rotate: 0 }}
          animate={{ rotate: isActive ? 180 : 0 }} // Rotate arrow on active state
          transition={{ duration: 0.3, ease: "easeInOut" }}
          className="ml-1 w-[12px] h-[6px]" // Adjust the size of the icon
        />
      </motion.p>
      {active !== null && (
        <motion.div
          initial={{ opacity: 0, scale: 0.85, y: 10 }}
          animate={{ opacity: 1, scale: 1, y: 0 }}
          transition={transition}
        >
          {isActive && (
            <div
              className="absolute top-[calc(100%_+_1.2rem)] left-1/2 transform -translate-x-1/2 pt-4"
            >
              <motion.div
                transition={transition}
                layoutId="active"
                className="bg-white dark:bg-black backdrop-blur-sm rounded-2xl overflow-hidden border border-black/[0.2] dark:border-white/[0.2] shadow-xl"
              >
                <motion.div
                  layout
                  className="w-max h-full p-4"
                >
                  {children}
                </motion.div>
              </motion.div>
            </div>
          )}
        </motion.div>
      )}
    </div>
  );
};

export const Menu = ({
  setActive,
  children
}) => {
  return (
    <nav
      onMouseLeave={() => setActive(null)}
      className="relative rounded-full shadow-input flex justify-center space-x-8 px-0 py-6"
    >
      {children}
    </nav>
  );
};

export const HoveredLink = ({
  children,
  ...rest
}) => {
  return (
    <Link
      {...rest}
      className="text-neutral-700 dark:text-neutral-200 hover:text-black"
    >
      {children}
    </Link>
  );
};
