"use client";
import React, { useCallback, useEffect, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { cn } from "../../lib/utils";

export const FlipWords = ({
  words,
  duration = 3000,
  className,
  lang = "EN", // Accept `lang` prop
}) => {
  const [currentWord, setCurrentWord] = useState(words[0]);
  const [isAnimating, setIsAnimating] = useState(false);

  const startAnimation = useCallback(() => {
    const currentIndex = words.indexOf(currentWord);
    const nextWord = words[currentIndex + 1] || words[0]; // Loop back to the first word
    setCurrentWord(nextWord);
    setIsAnimating(true);
  }, [currentWord, words]);

  useEffect(() => {
    // Reset current word when `words` or `lang` changes
    setCurrentWord(words[0]);
    setIsAnimating(false); // Ensure animation starts fresh
  }, [words, lang]);

  useEffect(() => {
    if (!isAnimating) {
      const timer = setTimeout(() => {
        startAnimation();
      }, duration);
      return () => clearTimeout(timer); // Cleanup timer on component unmount or dependency change
    }
  }, [isAnimating, duration, startAnimation]);

  return (
    <AnimatePresence
      onExitComplete={() => {
        setIsAnimating(false); // Allow next animation cycle
      }}
    >
      <motion.div
        key={`${lang}-${currentWord}`} // Key includes `lang` to ensure re-render
        initial={{
          opacity: 0,
          y: 10,
        }}
        animate={{
          opacity: 1,
          y: 0,
        }}
        transition={{
          type: "spring",
          stiffness: 100,
          damping: 10,
        }}
        exit={{
          opacity: 0,
          y: -40,
          x: lang === "AR" ? -40 : 40, // Adjust exit direction for Arabic
          filter: "blur(8px)",
          scale: 1.2,
          position: "absolute",
        }}
        className={cn(
          "z-10 inline-block relative text-left text-neutral-900 dark:text-neutral-100 px-2",
          lang === "AR" ? "text-right" : "text-left", // Adjust text alignment for Arabic
          className
        )}
      >
        {/* Render the entire word instead of splitting into letters */}
        <motion.span
          initial={{ opacity: 0, y: 10, filter: "blur(8px)" }}
          animate={{ opacity: 1, y: 0, filter: "blur(0px)" }}
          transition={{
            duration: 0.5,
          }}
          className="inline-block"
        >
          {currentWord}
        </motion.span>
      </motion.div>
    </AnimatePresence>
  );
};
