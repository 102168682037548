import { useState } from "react";
import { Link } from "react-router-dom"; // Keep this for external links
import { HoveredLink, Menu, MenuItem } from "./ui/NavbarMenu";
import { ChevronDownIcon, ChevronUpIcon, MoonIcon, SunIcon } from "lucide-react";
import AR from "../../src/data/ar.json";
import EN from "../../src/data/en.json";

const Navbar = ({ lang, toggleLanguage }) => {
  const [active, setActive] = useState(null);
  let Data = EN["NavBar"];
  const [language, setLanguage] = useState("EN");
  const [theme, setTheme] = useState("light");

  const toggleTheme = () => setTheme(theme === "light" ? "dark" : "light");
  Data = lang === "EN" ? EN["NavBar"] : AR["NavBar"];
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  const handleNavigation = (url) => {
    if (url.startsWith("#")) {
      const section = document.querySelector(url);
      if (section) {
        section.scrollIntoView({ behavior: "smooth" });
      }
    }
  };

  // Filter out hidden menu items
  const hiddenMenus = ["FarmTopia Platform", "Spotlight", "Resources" , "منصة فارمتوبيا" , "الاخبار المهمه" , "الموارد"];
  const filteredData = Data.filter((menuItem) => !hiddenMenus.includes(menuItem.mainLink));

  return (
    <nav className="sticky top-0 w-full h-16 flex justify-between items-center px-6 bg-white shadow-md z-50">
           {/* Mobile Icons */}
           <button
          className="mobileNav block md:hidden focus:outline-none"
          onClick={toggleMenu}
        >
          <svg
            className="w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M4 6h16M4 12h16m-7 6h7"
            />
          </svg>
        </button>
      {/* Logo */}
      <div className="flex items-center space-x-4 NavWeb">
        <Link to="/">
          <img
            src="/img/Light/logo.png"
            alt="FarmTopia logo"
            className="object-contain w-20 h-20"
          />
        </Link>
      </div>   

      {/* Links */}
      <div className="flex items-center z-50 space-x-8 md:hidden NavWeb" >
        {filteredData.map((navItem, index) => {
          if (navItem.subMenu && navItem.subMenu.length > 0) {
            // Render menu with submenu
            return (
              <Menu setActive={setActive} key={index}>
                <MenuItem
                  setActive={setActive}
                  active={active}
                  item={navItem.mainLink}
                  lang={lang}
                >
                  <div
                    className={`flex bg-white z-50 flex-col space-y-4 text-sm ${
                      lang === "EN" ? "" : "ar"
                    }`}
                  >
                    {navItem.subMenu.map((subItem, subIndex) => (
                      <HoveredLink href={subItem.url} key={subIndex}>
                        {subItem.name}
                      </HoveredLink>
                    ))}
                  </div>
                </MenuItem>
              </Menu>
            );
          } else {
            // Check if the navItem is for "Pricing" or another section that should scroll
            const isAnchorLink = navItem.url && navItem.url.startsWith("#");

            return isAnchorLink ? (
              // For anchor links, use handleNavigation for smooth scroll
              <a
                key={index}
                onClick={() => handleNavigation(navItem.url)}
                className={`cursor-pointer text-sm ${
                  lang === "EN" ? "" : "ar navr"
                }`}
              >
                {navItem.mainLink}
              </a>
            ) : (
              <a
              key={index}
              href={navItem.url}
              className={` cursor-pointer text-sm ${
                lang === "EN" ? "" : "ar navr"
              }`}
            >
              {navItem.mainLink}
            </a>
            );
          }
        })}
      </div>

      {isMenuOpen && (
         <div className={`fixed top-0 right-0 w-2/3 max-w-xs bg-white h-full shadow-lg transform ${
          isMenuOpen ? "translate-x-0" : "translate-x-full"
        } transition-transform duration-300 z-30`}>
            {/* Logo */}
      <div className="flex text-center items-center space-x-4">
        <Link to="/">
          <img
            src="/img/Light/logo.png"
            alt="FarmTopia logo"
            className="object-contain h-20"
          />
        </Link>
        <button
          className="mobileNav md:hidden focus:outline-none"
          onClick={toggleMenu}
        >
          <svg
            className="w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
           <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M15 19l-7-7 7-7" // Back arrow path
        />
          </svg>
        </button>
      </div>   

      {/* Links */}
      <div className="block items-center" >
        {filteredData.map((navItem, index) => {
          if (navItem.subMenu && navItem.subMenu.length > 0) {
            // Render menu with submenu
            return (
              <Menu setActive={setActive} key={index}>
                <MenuItem
                  setActive={setActive}
                  active={active}
                  item={navItem.mainLink}
                  lang={lang}
                >
                  <div
                    className={`block bg-white z-50 flex-col space-y-4 text-sm ${
                      lang === "EN" ? "" : "ar"
                    }`}
                  >
                    {navItem.subMenu.map((subItem, subIndex) => (
                      <HoveredLink href={subItem.url} key={subIndex}>
                        {subItem.name}
                      </HoveredLink>
                    ))}
                  </div>
                </MenuItem>
              </Menu>
            );
          } else {
            // Check if the navItem is for "Pricing" or another section that should scroll
            const isAnchorLink = navItem.url && navItem.url.startsWith("#");

            return isAnchorLink ? (
              // For anchor links, use handleNavigation for smooth scroll
              <a
                key={index}
                onClick={() => handleNavigation(navItem.url)}
                className={`block cursor-pointer text-sm ${
                  lang === "EN" ? "navr" : "ar navr"
                }`}
              >
                {navItem.mainLink}
              </a>
            ) : (
              // For other external links
              <a
              key={index}
              href={navItem.url}
              className={`block cursor-pointer text-sm ${
                lang === "EN" ? "navr" : "ar navr"
              }`}
            >
              {navItem.mainLink}
            </a>
            );
          }
        })}
      </div>
         </div>
      )}
      {/* Right Buttons */}
      <div className="flex items-center space-x-4">
        {/* Language Switcher */}
        <button className="text-sm" onClick={toggleLanguage}>
          {lang}
        </button>

        {/* Theme Switcher */}
        <button
          onClick={toggleTheme}
          className="p-2 font-bold text-lg rounded-full"
        >
          {theme === "light" ? (
            <SunIcon className="h-6 w-6" />
          ) : (
            <MoonIcon className="h-6 w-6" />
          )}
        </button>

        {/* Get Started Button */}
        <a
          href="https://beta.platform.farmtopia.farm/login"
          target="_blank"
          rel="noopener noreferrer"
        >
          <h5 className="px-4 py-2 bg-emerald-500 text-white hover:!text-emerald-500 hover:!bg-white rounded-md transition duration-200 border-2 border-transparent hover:!border-emerald-500">
            {lang === "EN" ? "Get Started" : "ابدأ الان"}
          </h5>
        </a>
      </div>
    </nav>
  );
};

export default Navbar;