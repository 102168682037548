import React from 'react';
import { InfiniteMovingLogos } from "./ui/InfiniteMovingCards"; // Update this to match the correct export
import { BlurFade } from './ui/BlurFade';
import AR from "../../src/data/ar.json"
import EN from "../../src/data/en.json"
import "../styles/Partner.css";

export const PartnersSection = ({ lang }) => {
  let Data = EN["Partner"];
  lang == "EN" ? Data = EN["Partner"] : Data = AR["Partner"];
  return (
    <div className="w-full pb-14 text-center justify-center items-center">

      <div className="container">
        <BlurFade delay={0.25} inView>
          <h2 className="heading">
            <span className="green">
              {Data.greenTitle}
            </span>{" "}
            <span className="black">
              {Data.title}
            </span>
          </h2>
        </BlurFade>
        <BlurFade delay={0.25 * 2} inView>
          <p className="subtitle">{Data.subTitle}</p>
        </BlurFade>
      </div>


      <div className=" rounded-md  flex flex-col antialiased bg-transparent dark:bg-grid-white/[0.05] items-center justify-center relative overflow-hidden" dir='ltr'>
        <InfiniteMovingLogos logos={logos} speed="normal" />
      </div>
    </div>
  );
}



const logos = [
  { src: "/img/Light/Partner-Firebase.png", alt: "Partner-Firebase" },
  { src: "/img/Light/Partner-GooglePlay.png", alt: "Partner-GooglePlay" },
  { src: "/img/Light/Partner-Microsoft.png", alt: "Partner-Microsoft" },
  { src: "/img/Light/Partner-OpenAI.png", alt: "Partner-OpenAI" },
  { src: "/img/Light/Partner-Planet.png", alt: "artner-Planet" },
  { src: "/img/Light/Partner-Sentinelhub.png", alt: "Partner-Sentinelhub" },
  // Add more logos as needed
];